.react-datepicker {
  color: var(--neutral-120);
  border: 1px solid var(--neutral-180);
}

.react-datepicker__header {
  background: transparent;
  border: none;
  padding-top: 8px;
}

.react-datepicker__day-name {
  color: var(--neutral-160);
  margin: 4px 6px;
  width: 24px;
  height: 24px;
}

.react-datepicker__day--keyboard-selected {
  background: inherit;
}

.react-datepicker__day {
  color: var(--neutral-120);
  font-size: 14px;
  line-height: 24px;
  margin: 4px 6px;
  width: 24px;
  height: 24px;
}

.react-datepicker__day--outside-month {
  color: var(--neutral-160);
}

.react-datepicker__day--disabled {
  color: var(--neutral-160);
}

.react-datepicker__day--today {
  color: var(--primary-100);
  background-color: var(--primary-190);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range),
.react-datepicker__day--in-range {
  background-color: var(--primary-100);
  color: var(--neutral-200);
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover {
  background-color: var(--primary-90);
}

.react-datepicker__time-container {
  background-color: var(--orange-100);
}
