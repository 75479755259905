:root {
  --primary-70: #b3535e;
  --primary-90: #e66a79;
  --primary-100: #ff7686;
  --primary-140: #ffadb6;
  --primary-170: #ffd6db;
  --primary-180: #ffe4e7;
  --primary-190: #fff1f3;

  --secondary-100: #9886b3;
  --secondary-190: #f4f2f7;

  --neutral-40: #1c1c1d;
  --neutral-60: #2b2b2c;
  --neutral-80: #39393a;
  --neutral-100: #474849;
  --neutral-120: #6c6d6e;
  --neutral-140: #919293;
  --neutral-160: #b5b6b7;
  --neutral-170: #d3d4d5;
  --neutral-180: #e9eaea;
  --neutral-190: #f8f9fa;
  --neutral-200: #ffffff;

  --info-100: #8cb9da;
  --info-190: #f4f9fc;

  --error-80: #be5e5e;
  --error-90: #d66969;
  --error-100: #ee7575;
  --error-190: #fdf1f1;

  --success-100: #84ca7e;
  --success-190: #f3faf2;

  --grey-100: #b5b5b6;
  --grey-190: #f8f8f8;

  --red-100: #ee7575;
  --red-190: #fdf1f1;

  --orange-100: #f69a67;
  --orange-190: #fef8f2;

  --yellow-100: #f1c506;
  --yellow-190: #fef9e6;

  --lime-100: #bad230;
  --lime-190: #f8fbea;

  --green-100: #81db79;
  --green-190: #f2fbf2;

  --turquoise-100: #62d4ba;
  --turquoise-190: #effbf8;

  --blue-100: #8cb9da;
  --blue-190: #f4f9fc;

  --violet-100: #ac9bdf;
  --violet-190: #f7f5fc;

  --purple-100: #d292dc;
  --purple-190: #fcf4fa;

  --pink-100: #f491b4;
  --pink-190: #fef7f9;

  --shadow-0: 0px 1px 2px rgba(0, 0, 0, 0.15);
  --shadow-1: 0px 0px 3px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.12);
  --shadow-2: 0px 0px 3px rgba(0, 0, 0, 0.05), 0px 4px 8px rgba(0, 0, 0, 0.12);
  --shadow-3: 0px 0px 3px rgba(0, 0, 0, 0.05), 0px 8px 24px rgba(0, 0, 0, 0.16);

  --border-radius-0: 0px;
  --border-radius-1: 4px;
  --border-radius-2: 8px;
  --border-radius-3: 16px;
  --border-radius-4: 24px;
  --border-radius-5: 32px;

  --backdrop: rgba(0, 0, 0, 0.5);

  --z-index-below: -1;
  --z-index-0: 0;
  --z-index-above: 1;
  --z-index-inf: 2147483647;
}

#root {
  min-height: 100vh;
  height: 100%;
  /* overflow-x: hidden; it fucks with sticky headers but i need it for the recipe grid  */
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  padding: 0;
}

img,
svg {
  display: block;
}

button {
  border: none;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
}

body {
  height: 100%;
  font-family: "Roboto", sans-serif;
  background-color: var(--background);
  color: var(--neutral-100);
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
  overflow: overlay;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 10px;
  border-radius: 8px;
}

*::-webkit-scrollbar-track {
  background: var(--neutral-180);
}

*::-webkit-scrollbar-thumb {
  background: var(--neutral-160);
  border-radius: 4px;
}

*::-webkit-scrollbar-thumb:hover {
  background: var(--neutral-140);
}
